(function () {
  'use strict';

  angular
    .module('dashboard.schools')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.schools', {
        url: '/schools',
        templateUrl: 'dashboard/schools/schools.tpl.html',
        controller: 'SchoolsCtrl',
        roles: ['superadmin', 'admin', 'Teacher'],
        controllerAs: 'schools'
      });
  }
}());
